import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import formatUrl from "utils/FormatUrl";
import style from "./style.module.css";

export const buildPrixImmobilierUrl = (region, province, commune) => {
  const url = `/prix-immobilier-maroc${
    region
      ? `/${region
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }${
    province
      ? `/${province
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }${
    commune
      ? `/${commune
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }`;
  return url;
};
export const buildVentesUrl = (region, province, commune) => {
  const url = `/annonces-achat${
    region
      ? `/${region
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }${
    province
      ? `/${province
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }${
    commune
      ? `/${commune
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }`;
  return url;
};
export const buildLocationsUrl = (region, province, commune) => {
  const url = `/location-appartement-maison${
    region
      ? `/${region
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }${
    province
      ? `/${province
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }${
    commune
      ? `/${commune
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .replace(/ /g, "-")}`
      : ""
  }`;
  return url;
};

function index(props) {
  const { lang } = useTranslation("LandingPage");
  const [showvente, setShowVente] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showPrixImmo, setShowPrixImmo] = useState(false);
  return (
    <div
      style={{
        background: "white",
      }}
    >
      <div className={style.LpsBtns}>
        <div className={style.content}>
          <span
            className={style.btn}
            onClick={() => {
              setShowVente(!showvente);
            }}
          >
            {props.btn1}
            {showvente ? (
              <span>
                <svg
                  width="15"
                  height="10"
                  viewBox="0 0 15 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2108_10857)">
                    <path
                      d="M14.6884 9.17001C14.5889 9.27462 14.4707 9.35761 14.3406 9.41423C14.2106 9.47086 14.0711 9.5 13.9303 9.5C13.7895 9.5 13.6501 9.47086 13.52 9.41423C13.3899 9.35761 13.2717 9.27462 13.1722 9.17001L7.50389 3.21808L1.83021 9.17001C1.62915 9.38113 1.35645 9.49974 1.07211 9.49974C0.78777 9.49974 0.515074 9.38113 0.314014 9.17001C0.112954 8.95889 0 8.67255 0 8.37399C0 8.07542 0.112954 7.78908 0.314014 7.57796L6.74311 0.827179C6.94388 0.617622 7.21545 0.5 7.49853 0.5C7.78161 0.5 8.05319 0.617622 8.25395 0.827179L14.6831 7.57796C14.783 7.68208 14.8625 7.80588 14.9169 7.94226C14.9712 8.07865 14.9995 8.22496 15 8.37282C15.0005 8.52068 14.9732 8.66719 14.9198 8.80398C14.8663 8.94077 14.7877 9.06515 14.6884 9.17001Z"
                      fill="#460AF0"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2108_10857">
                      <rect
                        width="15"
                        height="9"
                        fill="white"
                        transform="matrix(1 0 0 -1 0 9.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            ) : (
              <span>
                <svg
                  width="15"
                  height="10"
                  viewBox="0 0 15 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6884 0.829986C14.5889 0.725375 14.4707 0.642387 14.3406 0.585765C14.2106 0.529144 14.0711 0.5 13.9303 0.5C13.7895 0.5 13.6501 0.529144 13.52 0.585765C13.3899 0.642387 13.2717 0.725375 13.1722 0.829986L7.50389 6.78192L1.83021 0.829986C1.62915 0.618866 1.35645 0.50026 1.07211 0.50026C0.78777 0.50026 0.515074 0.618866 0.314014 0.829986C0.112954 1.04111 0 1.32745 0 1.62601C0 1.92458 0.112954 2.21092 0.314014 2.42204L6.74311 9.17282C6.94388 9.38238 7.21545 9.5 7.49853 9.5C7.78161 9.5 8.05319 9.38238 8.25395 9.17282L14.6831 2.42204C14.783 2.31792 14.8625 2.19412 14.9169 2.05774C14.9712 1.92135 14.9995 1.77504 15 1.62718C15.0005 1.47932 14.9732 1.33281 14.9198 1.19602C14.8663 1.05923 14.7877 0.934849 14.6884 0.829986Z"
                    fill="#460AF0"
                  />
                </svg>
              </span>
            )}
          </span>

          <div className={showvente ? style.provincesShow : style.provinces}>
            {props.provinces?.map((elem, index) => {
              return (
                <a
                  key={index}
                  href={formatUrl(
                    `/${lang}/${buildVentesUrl(
                      elem.region,
                      elem.province,
                      elem.commune
                    )}`,
                    lang
                  )}
                  className={style.link}
                  // onClick={(e) => {
                  //   void 0;
                  //   e.preventDefault();
                  // }}
                  // rel="no-refresh"
                >
                  {props.t("Annonces de vente à ")}{" "}
                  {props.t(elem.province.replace("-Ida -Ou-Tanane", ""))}
                </a>
              );
            })}
          </div>
        </div>
        <div className={style.content}>
          <span
            className={style.btn}
            onClick={() => {
              setShowLocation(!showLocation);
            }}
          >
            {props.btn2}
            {showLocation ? (
              <span>
                <svg
                  width="15"
                  height="10"
                  viewBox="0 0 15 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2108_10857)">
                    <path
                      d="M14.6884 9.17001C14.5889 9.27462 14.4707 9.35761 14.3406 9.41423C14.2106 9.47086 14.0711 9.5 13.9303 9.5C13.7895 9.5 13.6501 9.47086 13.52 9.41423C13.3899 9.35761 13.2717 9.27462 13.1722 9.17001L7.50389 3.21808L1.83021 9.17001C1.62915 9.38113 1.35645 9.49974 1.07211 9.49974C0.78777 9.49974 0.515074 9.38113 0.314014 9.17001C0.112954 8.95889 0 8.67255 0 8.37399C0 8.07542 0.112954 7.78908 0.314014 7.57796L6.74311 0.827179C6.94388 0.617622 7.21545 0.5 7.49853 0.5C7.78161 0.5 8.05319 0.617622 8.25395 0.827179L14.6831 7.57796C14.783 7.68208 14.8625 7.80588 14.9169 7.94226C14.9712 8.07865 14.9995 8.22496 15 8.37282C15.0005 8.52068 14.9732 8.66719 14.9198 8.80398C14.8663 8.94077 14.7877 9.06515 14.6884 9.17001Z"
                      fill="#460AF0"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2108_10857">
                      <rect
                        width="15"
                        height="9"
                        fill="white"
                        transform="matrix(1 0 0 -1 0 9.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            ) : (
              <span>
                <svg
                  width="15"
                  height="10"
                  viewBox="0 0 15 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6884 0.829986C14.5889 0.725375 14.4707 0.642387 14.3406 0.585765C14.2106 0.529144 14.0711 0.5 13.9303 0.5C13.7895 0.5 13.6501 0.529144 13.52 0.585765C13.3899 0.642387 13.2717 0.725375 13.1722 0.829986L7.50389 6.78192L1.83021 0.829986C1.62915 0.618866 1.35645 0.50026 1.07211 0.50026C0.78777 0.50026 0.515074 0.618866 0.314014 0.829986C0.112954 1.04111 0 1.32745 0 1.62601C0 1.92458 0.112954 2.21092 0.314014 2.42204L6.74311 9.17282C6.94388 9.38238 7.21545 9.5 7.49853 9.5C7.78161 9.5 8.05319 9.38238 8.25395 9.17282L14.6831 2.42204C14.783 2.31792 14.8625 2.19412 14.9169 2.05774C14.9712 1.92135 14.9995 1.77504 15 1.62718C15.0005 1.47932 14.9732 1.33281 14.9198 1.19602C14.8663 1.05923 14.7877 0.934849 14.6884 0.829986Z"
                    fill="#460AF0"
                  />
                </svg>
              </span>
            )}
          </span>

          <div className={showLocation ? style.provincesShow : style.provinces}>
            {props.provinces?.map((elem, index) => {
              return (
                <a
                  key={index}
                  href={formatUrl(
                    `/${lang}/${buildLocationsUrl(
                      elem.region,
                      elem.province,
                      elem.commune
                    )}`,
                    lang
                  )}
                  className={style.link}
                  // onClick={(e) => {
                  //   void 0;
                  //   e.preventDefault();
                  // }}
                  // rel="no-refresh"
                >
                  {props.t("Annonces de location à")}{" "}
                  {props.t(elem.province.replace("-Ida -Ou-Tanane", ""))}
                </a>
              );
            })}
          </div>
        </div>
        <div className={style.content}>
          <span
            className={style.btn1}
            onClick={() => {
              setShowPrixImmo(!showPrixImmo);
            }}
          >
            {props.btn3}
            {showPrixImmo ? (
              <span>
                <svg
                  width="15"
                  height="10"
                  viewBox="0 0 15 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2108_10857)">
                    <path
                      d="M14.6884 9.17001C14.5889 9.27462 14.4707 9.35761 14.3406 9.41423C14.2106 9.47086 14.0711 9.5 13.9303 9.5C13.7895 9.5 13.6501 9.47086 13.52 9.41423C13.3899 9.35761 13.2717 9.27462 13.1722 9.17001L7.50389 3.21808L1.83021 9.17001C1.62915 9.38113 1.35645 9.49974 1.07211 9.49974C0.78777 9.49974 0.515074 9.38113 0.314014 9.17001C0.112954 8.95889 0 8.67255 0 8.37399C0 8.07542 0.112954 7.78908 0.314014 7.57796L6.74311 0.827179C6.94388 0.617622 7.21545 0.5 7.49853 0.5C7.78161 0.5 8.05319 0.617622 8.25395 0.827179L14.6831 7.57796C14.783 7.68208 14.8625 7.80588 14.9169 7.94226C14.9712 8.07865 14.9995 8.22496 15 8.37282C15.0005 8.52068 14.9732 8.66719 14.9198 8.80398C14.8663 8.94077 14.7877 9.06515 14.6884 9.17001Z"
                      fill="#460AF0"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2108_10857">
                      <rect
                        width="15"
                        height="9"
                        fill="white"
                        transform="matrix(1 0 0 -1 0 9.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            ) : (
              <span>
                <svg
                  width="15"
                  height="10"
                  viewBox="0 0 15 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6884 0.829986C14.5889 0.725375 14.4707 0.642387 14.3406 0.585765C14.2106 0.529144 14.0711 0.5 13.9303 0.5C13.7895 0.5 13.6501 0.529144 13.52 0.585765C13.3899 0.642387 13.2717 0.725375 13.1722 0.829986L7.50389 6.78192L1.83021 0.829986C1.62915 0.618866 1.35645 0.50026 1.07211 0.50026C0.78777 0.50026 0.515074 0.618866 0.314014 0.829986C0.112954 1.04111 0 1.32745 0 1.62601C0 1.92458 0.112954 2.21092 0.314014 2.42204L6.74311 9.17282C6.94388 9.38238 7.21545 9.5 7.49853 9.5C7.78161 9.5 8.05319 9.38238 8.25395 9.17282L14.6831 2.42204C14.783 2.31792 14.8625 2.19412 14.9169 2.05774C14.9712 1.92135 14.9995 1.77504 15 1.62718C15.0005 1.47932 14.9732 1.33281 14.9198 1.19602C14.8663 1.05923 14.7877 0.934849 14.6884 0.829986Z"
                    fill="#460AF0"
                  />
                </svg>
              </span>
            )}
          </span>

          <div className={showPrixImmo ? style.provincesShow : style.provinces}>
            {props.provinces?.map((elem, index) => {
              return (
                <a
                  key={index}
                  href={formatUrl(
                    `/${lang}/${buildPrixImmobilierUrl(
                      elem.region,
                      elem.province,
                      elem.commune
                    )}`,
                    lang
                  )}
                  className={style.link}
                  // onClick={(e) => {
                  //   void 0;
                  //   e.preventDefault();
                  // }}
                  // rel="no-refresh"
                >
                  {props.t("Prix immobilier à")}{" "}
                  {props.t(elem.province.replace("-Ida -Ou-Tanane", ""))}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
