import useTranslation from "next-translate/useTranslation";
import React from "react";
import formatUrl from "utils/FormatUrl";
import style from "./style.module.css";

function index({
  title,
  texte,
  buttonText,
  button_link,
  forVendre,
}: {
  title: string;
  texte: string;
  buttonText: string;
  button_link: string;
  forVendre?: string;
}) {
  const { lang } = useTranslation("");
  return (
    <div
      className={`${style.container} ${
        forVendre === "Prêt à vendre ? " ? style.containerBg : null
      }`}
    >
      <div>
        <p className={style.question}>{title}</p>
        <p className={style.text}>{texte}</p>
      </div>
      <a
        className={style.button}
        href={formatUrl(button_link, lang)}
        rel={
          button_link?.includes("list") ||
          button_link?.includes("map") ||
          button_link?.includes("?") ||
          button_link?.includes("conditions") ||
          button_link?.includes("contact")
            ? "noindex, nofollow, noreferrer"
            : "dofollow"
        }
        onClick={(e) => {
          e.preventDefault();
          let params = new URLSearchParams(window.location.search);

          if (params.get("version")) {
            window.location.href = formatUrl(
              `${button_link}${params.size ? `?${params.toString()}` : ""}`,
              lang
            );
          } else {
            params.set("version", process.env.NEXT_PUBLIC_VERSION_ESTIMATION);

            window.location.href = formatUrl(
              `${button_link}${params.size ? `?${params.toString()}` : ""}`,
              lang
            );
          }
        }}
        target="_blank"
      >
        {buttonText}
      </a>
    </div>
  );
}

export default index;
