import React, { useState } from "react";
import Style from "./threeguidebuttons.module.css";
import { useRouter } from "next/router";
import formatUrl from "utils/FormatUrl";
function index({
  slug,
  button1,
  link_button1,
  button2,
  link_button2,
  button3,
  link_button3,
  lang,
}: {
  slug?: string;
  button1: string;
  link_button1: string;
  button2: string;
  link_button2: string;
  button3: string;
  link_button3: string;
  lang: string;
}) {
  const route = useRouter();

  const [check, setCheck] = useState(
    route.pathname == link_button3 || `/${slug}` == link_button3
      ? 3
      : route.pathname == link_button2 || `/${slug}` == link_button2
      ? 2
      : route.pathname == link_button1 || `/${slug}` == link_button1
      ? 1
      : 0
  );

  return (
    <div className={Style.btnCon}>
      <a
        href={formatUrl(link_button1, lang)}
        className={check == 1 ? Style.btnCheck : Style.btn}
        onClick={() => {
          setCheck(1);
        }}
      >
        {button1}
      </a>
      <a
        href={formatUrl(link_button2, lang)}
        className={check == 2 ? Style.btnCheck : Style.btn}
        onClick={() => {
          setCheck(2);
        }}
      >
        {button2}
      </a>
      <a
        href={formatUrl(link_button3, lang)}
        className={check == 3 ? Style.btnCheck : Style.btn}
        onClick={() => {
          setCheck(3);
        }}
      >
        {button3}
      </a>
    </div>
  );
}

export default index;
