import useTranslation from "next-translate/useTranslation";
import React from "react";
import formatUrl from "utils/FormatUrl";
import style from "./style.module.css";

function Question(props) {
  // const url = `https://mag.agenz.ma/${props.button_link}`
  const url = `${props.button_link}`;
  const { lang } = useTranslation("");
  return (
    <div
      className={style.container}
      style={{
        backgroundImage: props.image ? `url(${props.image})` : "",
        backgroundSize: "contain",
      }}
    >
      {props.blockquote ? (
        <blockquote>
          <p
            className={style.question}
            style={{ color: props.color ? props.color : "black" }}
          >
            {props.title}
          </p>
        </blockquote>
      ) : (
        <p
          className={style.question}
          style={{ color: props.color ? props.color : "black" }}
        >
          {props.title}
        </p>
      )}
      <p
        className={style.paragraph}
        style={{ color: props.color ? props.color : "black" }}
      >
        <span
          style={{
            color: "#32e696",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {props.text_color ? props.text_color : null}
        </span>
        {props.text}
      </p>
      {props.buttonText && (
        <a
          data-tracking="click"
          data-value=""
          className={style.button}
          rel={
            url?.includes("list") ||
            url?.includes("map") ||
            url?.includes("?") ||
            url?.includes("conditions") ||
            url?.includes("contact")
              ? "noindex, nofollow, noreferrer"
              : "dofollow"
          }
          href={formatUrl(url, lang)}
          target="_blank"
          style={{ color: "#fff" }}
        >
          {props.buttonText}
        </a>
      )}
    </div>
  );
}

export default Question;
